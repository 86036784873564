@import url("https://cdn.syncfusion.com/ej2/material.css");
/**********************Title*****************/
.stats-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin: 1rem;
  margin-top: 0.5rem;
}
.stats {
  width: 100%;
  min-width: 600px;
  height: 100%;
}
/**********************Container*****************/
.stats-container-wrapper {
  display: grid;
  grid-template-columns: 49% 23% 23%;
  grid-template-rows: 60% 35%;
  column-gap: 2%;
  row-gap: 2%;
  height: 90%;
  margin: 1rem;
}
.stats-container {
  position: relative;
  border: 2px solid var(--text-color);
  background-color: var(--secondary-color);
  color: var(--text-color);
  border-radius: 10px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
}

.highscore-container {
  grid-column: 1/2;
  grid-row: 1/3;
}
.admin-container {
  background-color: white;
  color: red;
  min-width: 660px;

  grid-column: 2/4;
  grid-row: 1/2;
  max-width: 100%;
  min-width: 90%;
  min-width: 600px;
}
.data-container {
  grid-column: 2/2;
  grid-row: 2/3;
}
.weather-container {
  grid-column: 3/4;
  grid-row: 2/3;
}
/**********************Highscores*****************/
.highscore-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
}
.highscore-card {
  border: 2px solid var(--text-color);
  width: 80%;
  border-radius: 10px;
  height: 6%;
  background-color: rgb(50, 92, 168);
  display: grid;
  grid-template-columns: 10% 65% 25%;
  font-size: 20px;
  font-weight: 800;
  align-items: center;
}
.highscore-playerName {
  text-align: left;
  grid-column: 2/3;
  grid-row: 1/2;
}
.highscore-points {
  text-align: left;
  grid-column: 3/4;
  grid-row: 1/2;
}
