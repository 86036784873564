:root {
	--primary-color: #353131;
	--secondary-color: rgba(255, 255, 255, 0.2);
	--secondary-color: rgba(255, 255, 255, 0.2);
	--tertiary-color: rgb(92, 92, 92);

	--text-color: #fff;
	--text-color-dark: #000;

	--primary-text-color: #222222;
	--secondary-text-color: #2d1f1f;
	--tertiary-text-color: #5d5555;

	--orange-dice-color: #ff7b00;
	--blue-dice-color: #6769af;
	--green-dice-color: #378232;
	--yellow-dice-color: #f1d712;
	--purple-dice-color: #a76f9f;
	--white-dice-color: #f0f0f0;

	--orange-field-color: #9c4d03;
	--blue-field-color: rgba(89, 111, 189, 0.5);
	--green-field-color: rgba(71, 185, 29, 0.5);
	--yellow-field-color: rgba(218, 173, 14, 0.5);
	--purple-field-color: rgba(162, 66, 179, 0.5);

	--orange-border-color: #ff8d14;
	--blue-border-color: rgba(11, 169, 202);
	--green-border-color: rgba(106, 255, 54, 1);
	--yellow-border-color: #e1c80a;
	--purple-border-color: rgb(181, 8, 211);

	--purple-font-color: rgb(204, 118, 221);

	--fox-color: red;
	--item-color: black;
}

body,
html {
	overflow: hidden;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--primary-color);
	cursor: default;
}

