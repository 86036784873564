.game {
	width: 100%;
	height: 100%;
}
.game-container {
	display: flex;
	justify-content: center;
	margin-top: auto;
	margin-bottom: auto;
}

.left-container-game {
	height: 100%;
	width: 50%;
}
.right-container-game {
	height: 100%;
	width: 50%;
}
.board {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 2rem;
	gap: 3rem;
	max-width: 720px;
}
.other-players-board {
	position: absolute;
	transform: scale(0.2) translateY(250%);
	bottom: 0;
}
.other-players-board:hover {
	top: 10px;
	bottom: auto;
	margin-left: 5em;
	padding-right: 2em;
	padding-bottom: 4em;
	transform: scale(1);
	background-color: var(--primary-color);
	z-index: 100;
}
.board-1 {
	margin-left: 200px;
}
.board-2 {
	margin-left: 400px;
}
.player-name {
	color: var(--text-color);
}

.controlls {
	display: grid;
	padding: 2rem;
	gap: 1rem;
	grid-template-columns: repeat(8, 1fr);
	width: 645px;
	min-width: 645px;
}

.out-layer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.164);
	z-index: 3;
}

.your-turn {
	color: var(--text-color);
	animation: yourTurn 1s;
}

@keyframes yourTurn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

