.landing {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.landing-button,
.join-lobby {
	border: 3px solid var(--text-color);
	border-radius: 10px;

	color: var(--text-color);
	font-size: 1.5rem;
	width: 40%;
	height: 60px;
	margin: 10px;
	cursor: pointer;
}
.landing-button {
	background-color: var(--secondary-color);
}
.landing-button:hover {
	background-color: var(--primary-color);
}
.join-lobby {
	box-sizing: border-box;
	display: flex;
	cursor: default;
}
.join-button {
	width: 20%;
	height: 100%;
	font-size: 1.5rem;
	float: right;
	background-color: var(--secondary-color);
	color: var(--text-color);
	border: none;
	border-left: 3px solid var(--text-color);
	cursor: pointer;
}
.join-button:hover {
	border-radius: 0px 10px 10px 0px;
	background-color: var(--primary-color);
}

#lobbyInput {
	width: 80%;
	height: 100%;
	float: left;
	border: none;
	background-color: var(--secondary-color);
	color: var(--text-color);
	padding-left: 10px;
	font-size: 1.2rem;
}
#lobbyInput::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: var(--text-color);
	opacity: 0.6; /* Firefox */
}

#lobbyInput:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: var(--text-color);
}

#lobbyInput::-ms-input-placeholder {
	/* Microsoft Edge */
	color: var(--text-color);
}

.one-dash {
	position: relative;
	color: var(--text-color);
	top: 14rem;
}
.one-dash-link {
	color: var(--text-color);
	text-decoration: none;
	font-weight: bold;
}

@media (max-width: 750px) {
	.landing-button,
	.join-lobby,
	.join-button,
	#lobbyInput,
	#start-game-button,
	div > .lobby,
	button > .lobby {
		font-size: 1rem;
	}
}
@media (max-width: 510px) {
	.landing-button,
	.join-lobby,
	.join-button,
	#start-game-button,
	#lobbyInput,
	div > .lobby,
	button > .lobby,
	.chat div {
		font-size: 0.8rem;
	}
}
@media (max-width: 400px) {
	.landing-button,
	.join-lobby,
	.join-button,
	#start-game-button,
	#lobbyInput,
	div > .lobby,
	button > .lobby,
	.chat div {
		font-size: 0.6rem;
	}
}
@media (max-width: 300px) {
	.landing-button,
	.join-lobby,
	.join-button,
	#start-game-button,
	#lobbyInput,
	div > .lobby,
	button > .lobby,
	.chat div {
		font-size: 0.5rem;
	}
}

