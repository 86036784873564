.roll-again-container,
.plus-one-container {
	border: 3px solid var(--text-color);
	border-radius: 10px;
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	justify-content: space-around;
	align-items: center;
	padding: 0.5rem;
	background-color: var(--secondary-color);
}

.roll-again-display,
.plus-one-display {
	border: 2px solid var(--text-color);
	border-radius: 10px;
	color: var(--text-color);
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	font-weight: 500;
	z-index: 1;
	height: 50px;
	width: 50px;
	background-color: var(--text-color-dark);
}

.roll-again,
.plus-one,
.roll-again {
	justify-self: center;
	border: 4px solid var(--text-color);
	border-radius: 50%;
	height: 35px;
	width: 35px;
	margin: 0px 10px 0px 10px;
}
.roll-again.unlocked,
.plus-one.unlocked {
	border-color: var(--text-color-dark);
}
.plus-one.unlocked {
	cursor: pointer;
}
.plus-one.unlocked:hover {
	border-color: rgb(59, 59, 59);
}
.roll-again.used,
.plus-one.used {
	border-color: var(--text-color-dark);
	font-size: 1.8rem;
	font-weight: bolder;
}

/******************* Rounds ***********************/

.rounds-container {
	display: grid;
	grid-template-columns: repeat(6, 70px);
	gap: 15px;
	position: relative;
	justify-content: space-between;
}

.round {
	background-color: var(--text-color);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	font-weight: bold;
	position: relative;
	width: 50px;
	height: 50px;
}
.round-group {
	border: 2px solid var(--text-color);
	background-color: var(--secondary-color);
	border-radius: 10px;
	padding: 0.5rem;
	height: 85px;
	width: 50px;
}

.menu-button {
	background-color: var(--secondary-color);
	height: 50px;
	width: 50px;
	min-width: 50px;
	border-radius: 50%;
}
.item-rounds {
	transform: scale(0.6);
}

.round-running {
	border: 5px solid rgb(0, 207, 0);
}
.round-finished {
	border: 5px solid rgb(109, 0, 0);
}

/*  Components Positions  */
.used-dice-container {
	grid-column: 1;
	grid-row: 1/4;
}
.rounds-container {
	grid-column: 2/9;
	grid-row: 1;
}
.roll-again-container {
	grid-column: 2/9;
	grid-row: 2;
}
.plus-one-container {
	grid-column: 2/9;
	grid-row: 3;
}
.dice-container {
	grid-column: 1/5;
	grid-row: 6/8;
}
.out-dice-container {
	grid-column: 1/5;
	grid-row: 4/6;
}
.chat-container {
	grid-column: 5/9;
	grid-row: 4/6;
}
.points-container {
	grid-column: 5/9;
	grid-row: 6/8;
}
.roll-dice-button {
	grid-column: 1/5;
	grid-row: 8;
}
.skip-button {
	grid-column: 5/6;
	grid-row: 8;
}
.end-turn-button {
	grid-column: 6/9;
	grid-row: 8;
}

