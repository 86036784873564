.points-container {
	padding: 1rem;
	color: var(--text-color);
	box-sizing: border-box;
	border: 3px solid var(--text-color);
	border-radius: 10px;
	background-color: var(--secondary-color);
	text-align: start;
	font-size: 0.8rem;
}
.points-purple {
	color: var(--purple-dice-color);
}
.points-yellow {
	border-top: 3px solid var(--text-color);
	color: var(--yellow-dice-color);
}
.points-blue {
	color: var(--blue-dice-color);
}
.points-green {
	color: var(--green-dice-color);
}
.points-orange {
	color: var(--orange-dice-color);
}
.points-total {
	color: rgb(20, 20, 20);
	border-bottom: 3px solid var(--text-color);
}
.points-fox {
	color: #be4f4f;
	border-bottom: 3px solid var(--text-color);
}
.points {
	font-weight: 800;
}
.numbers {
	float: right;
}

.end-screen {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--primary-color);
	z-index: 100;
	display: block;
	justify-content: center;
	align-items: center;
	color: var(--text-color);
}
.back-to-menu {
	box-sizing: border-box;
	background-color: var(--secondary-color);
	color: var(--text-color);
	border: 3px solid var(--text-color);
	border-radius: 10px;
	font-size: 1.2rem;
	cursor: pointer;
	padding: 10px;
	width: 400px;
	margin-top: 10rem;
}
.back-to-menu:hover {
	background-color: var(--primary-color);
	color: var(--text-color);
}
#charts {
	margin-top: 3rem;
	width: 80%;
	padding: 1rem;
	min-height: 400px;
}
.rund-stats-container {
	display: flex;
}
.highscore-table {
	width: 20%;
	min-height: 400px;
	padding: 1rem;
	padding-left: 3rem;
	padding-right: 3rem;
	display: grid;
	margin-top: 1rem;
}
.score {
	width: 100%;
	border-top: 3px solid rgb(134, 134, 134);
	justify-content: space-between;
	display: flex;
}
.index {
	margin-left: 1rem;
}
.your-highscore-points {
	margin-right: 1rem;
}
.head-line {
	margin-top: 2rem;

	font-size: 3rem;
	font-weight: 800;
}
.winner-text {
	margin-top: 2rem;

	font-size: 2rem;
	font-weight: 800;
}
