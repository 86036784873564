.chat-container {
	box-sizing: border-box;
	background-color: var(--secondary-color);
	border-radius: 10px;
	border: 3px solid var(--text-color);
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
}
.right-container-lobby .chat-container {
	height: 90%;
}

.chat-container .title {
	color: var(--text-color);
	padding-bottom: 20px;
}
.chat-form {
	display: flex;
	width: 100%;
	height: 30px;
	position: absolute;
	bottom: 0;
}
.chat-inner-container {
	position: absolute;
	top: 20px;
	width: 100%;
	bottom: 35px;
	overflow: hidden;
}
.chat {
	padding-right: 10px;
	padding-left: 10px;
	overflow: hidden;
	height: inherit;
	font-size: 0.9rem;
	font-weight: 400;
	bottom: 0;
	position: absolute;
	min-height: 100%;
}
.chat div {
	padding: 0px 10px 0px 10px;
	overflow: hidden;
	text-align: left;
}
#chat-input {
	width: 100%;
	height: 100%;
	background: none;
	border: none;
	border-top: 1px solid var(--text-color);
	border-right: 1px solid var(--text-color);
	color: var(--text-color);
	padding: 0px 10px 0px 10px;
}

#chat-input::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: var(--text-color);
	opacity: 0.7; /* Firefox */
}

#chat-input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: var(--text-color);
}

#chat-input::-ms-input-placeholder {
	/* Microsoft Edge */
	color: var(--text-color);
}
input:focus-visible {
	outline: none;
	border: 2px solid var(--text-color);
}
.chat-form button {
	width: 100px;
	height: 100%;
	background: none;
	border: none;
	border-top: 1px solid var(--text-color);
	cursor: pointer;
	color: var(--text-color);
}
.chat-form button:hover {
	background-color: var(--secondary-color);
}
