/***********Dice && Dice-Container*************/
.all-dice-containers {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.dice-container,
.out-dice-container {
	display: grid;
	padding: 1rem;
	gap: 0.5rem;
	grid-template-columns: repeat(3, 50px);
	grid-template-rows: repeat(3, 50px);
	color: var(--text-color);
	box-sizing: border-box;
	border: 3px solid var(--text-color);
	border-radius: 10px;
	background-color: var(--secondary-color);
}

.used-dice-container {
	position: relative;
	display: grid;
	grid-template-columns: repeat(1, 62px);
	grid-template-rows: repeat(3, 62px);
	gap: 1.5rem;
}
.dice-container-title {
	font-size: 1.2rem;
	grid-column: 1 / 4;
}

.dice-box {
	position: absolute;
	width: 50px;
	height: 50px;
	border-radius: 5px;
	border: 5px solid rgb(209, 209, 209);
	background-color: var(--secondary-color);
}
.one {
	top: 0;
	left: 0;
	margin-left: 2rem;
	transform: translateX(-50%);
}
.two {
	top: 50%;
	left: 0;
	margin-left: 2rem;
	transform: translate(-50%, -50%);
}
.three {
	bottom: 0;
	left: 0;
	margin-left: 2rem;
	transform: translateX(-50%);
}

.roll-dice-button,
.skip-button,
.end-turn-button {
	box-sizing: border-box;
	background-color: var(--secondary-color);
	color: var(--text-color);
	border: 3px solid var(--text-color);
	border-radius: 10px;
	font-size: 1.2rem;
	cursor: pointer;
	padding: 10px;
}
.roll-dice-button:hover,
.skip-button:hover,
.end-turn-button:hover {
	background-color: var(--primary-color);
	color: var(--text-color);
}
/*********Dice**********/
.yellow {
	background-color: var(--yellow-dice-color);
}
.orange {
	background-color: var(--orange-dice-color);
}
.blue {
	background-color: var(--blue-dice-color);
}
.green {
	background-color: var(--green-dice-color);
}
.white {
	background-color: var(--white-dice-color);
}
.purple {
	background-color: var(--purple-dice-color);
}

.dice {
	box-sizing: border-box;
	width: 50px;
	height: 50px;
	border: 1px solid black;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.dice-dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: black;
	position: absolute;
}
.dice-top-left {
	top: 5px;
	left: 5px;
}
.dice-top-right {
	top: 5px;
	right: 5px;
}
.dice-bottom-left {
	bottom: 5px;
	left: 5px;
}
.dice-bottom-right {
	bottom: 5px;
	right: 5px;
}
.dice-middle-left {
	left: 5px;
}
.dice-middle-right {
	right: 5px;
}

/* Selected */
.selected {
	border: 4px solid green;
}

