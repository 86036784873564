/*********************** Items *************************/
.item-all-colors,
.item-yellow,
.item-blue,
.item-orange,
.item-green,
.item-purple,
.item-plus-one,
.item-roll-again {
	border: 2px solid var(--text-color);
	border-radius: 10px;
	color: var(--text-color);
	width: 80%;
	aspect-ratio: 1 / 1;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	font-weight: 500;
	z-index: 1;
}
.item-blue {
	background-color: var(--blue-border-color);
}
.item-orange {
	background-color: var(--orange-border-color);
}
.item-green {
	background-color: var(--green-border-color);
}
.item-yellow {
	background-color: var(--yellow-border-color);
}
.item-purple {
	background-color: var(--purple-border-color);
}

.item-fox {
	color: rgb(131, 22, 22);
	font-size: 3.5rem;
	z-index: 1;
	margin-top: -5px;
}
.green-fields-item.item-fox,
.orange-fields-item.item-fox,
.purple-fields-item.item-fox {
	margin-top: -20px;
}
.item-all-colors,
.item-plus-one,
.item-roll-again {
	background-color: var(--item-color);
}
.green-fields-item,
.orange-fields-item,
.purple-fields-item {
	transform: scale(0.8);
	margin-top: -12px;
}

/*********************** Fields *************************/
.yellow-container,
.blue-container {
	border: 2px solid var(--text-color);
	border-radius: 10px;
	display: grid;
	grid-template-columns: repeat(5, 50px);
	grid-template-rows: repeat(5, 50px);
	gap: 1rem;
	padding: 1rem;
	width: fit-content;
}
.yellow-container {
	grid-template-columns: repeat(5, 50px);
	grid-template-rows: repeat(5, 50px);
}

.green-container,
.purple-container,
.orange-container {
	border: 2px solid var(--text-color);
	display: flex;
	border-radius: 10px;
	padding: 0.2rem;
	grid-column: 1/3;
	justify-content: space-around;
	padding-top: 27px;
}

.orange-container,
.purple-container {
	padding-top: 0.5rem;
}

.field,
.yellow-field,
.blue-field,
.orange-field,
.green-field,
.purple-field {
	background-color: var(--text-color);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	font-weight: bold;
	position: relative;
	width: 50px;
	height: 50px;
}

.green-container {
	background-color: var(--green-field-color);
	box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 10px var(--green-border-color), 0 0 15px var(--green-border-color),
		0 0 20px var(--green-border-color), 0 0 25px var(--green-border-color) inset;
}
.yellow-container {
	background-color: var(--yellow-field-color);
	box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 10px var(--yellow-border-color), 0 0 15px var(--yellow-border-color),
		0 0 20px var(--yellow-border-color), 0 0 25px var(--yellow-border-color) inset;
}
.blue-container {
	background-color: var(--blue-field-color);
	box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 10px var(--blue-border-color), 0 0 15px var(--blue-border-color),
		0 0 20px var(--blue-border-color), 0 0 25px var(--blue-border-color) inset;
}
.orange-container {
	background-color: var(--orange-field-color);
	box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 10px var(--orange-border-color), 0 0 15px var(--orange-border-color),
		0 0 20px var(--orange-border-color), 0 0 25px var(--orange-border-color) inset;
}
.purple-container {
	background-color: var(--purple-field-color);
	box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 10px var(--purple-border-color), 0 0 15px var(--purple-border-color),
		0 0 20px var(--purple-border-color), 0 0 25px var(--purple-border-color) inset;
}

/*********************** Points *************************/
.point {
	margin: auto;
	width: 80%;
	aspect-ratio: 1 / 1;
	background-color: var(--text-color);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
}

.field-group {
	position: relative;
}

.field-group .point {
	position: absolute;
	top: -43px;
	left: 50%;
	transform: translateX(-50%) scale(0.8);
}
.green-container .point {
	border: 2px solid var(--green-border-color);
}
.point div {
	width: 100%;
	transform: translateY(-8%);
}
/*********************** Startfields *************************/
.start-field {
	height: 50px;
	width: 50px;

	border-radius: 10px;
	-webkit-box-shadow: inset 0px 0px 0px 2px var(--text-color);
	-moz-box-shadow: inset 0px 0px 0px 2px var(--text-color);
	box-shadow: inset 0px 0px 0px 2px var(--text-color);
	position: relative;
}

.green-container .start-field,
.green-container .start-field::after {
	background-color: var(--green-border-color);
	border-color: var(--green-border-color);
}

.orange-container .start-field,
.orange-container .start-field::after {
	background-color: var(--orange-border-color);
}

.purple-container .start-field {
	background-color: var(--purple-border-color);
}

.green-container .start-field::after,
.orange-container .start-field::after {
	content: "";
	box-sizing: border-box;

	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 125%;
	width: 650px;
	border: 2px solid;
}
.green-container .start-field::after {
	border-color: var(--green-border-color);
	box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 10px var(--green-border-color), 0 0 15px var(--green-border-color),
		0 0 20px var(--green-border-color), 0 0 25px var(--green-border-color) inset;
}
.orange-container .start-field::after {
	border-color: var(--orange-border-color);
	box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 10px var(--orange-border-color), 0 0 15px var(--orange-border-color),
		0 0 20px var(--orange-border-color), 0 0 25px var(--orange-border-color) inset;
}
.start-field-arrow {
	height: 15px;
	width: 15px;
	background-color: var(--text-color);
	top: 50%;
	left: 35%;
	transform: translate(-50%, -50%);
	position: absolute;
}
.start-field-arrow::after {
	display: block;
	content: "";
	width: 0px;
	height: 0px;
	left: 100%;
	position: relative;
	bottom: 50%;

	border-top: 15px solid transparent;
	border-right: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-left: 15px solid var(--text-color);
}
/*********************** Yellow *************************/
.yellow-container {
	background-color: var(--yellow-field-color);
}

/** Arrows **/
.yellow-field.row-1:after {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 100%;
	height: 250px;
	border: 2px solid var(--yellow-field-color);
	box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 10px var(--yellow-border-color), 0 0 15px var(--yellow-border-color),
		0 0 20px var(--yellow-border-color), 0 0 25px var(--yellow-border-color) inset;
}
.yellow-field.column-1:before {
	content: "";
	box-sizing: border-box;

	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 100%;
	width: 250px;
	border: 2px solid var(--yellow-field-color);
	box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 10px var(--yellow-border-color), 0 0 15px var(--yellow-border-color),
		0 0 20px var(--yellow-border-color), 0 0 25px var(--yellow-border-color) inset;
}
.yellow-field.column-2.row-2:after {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	transform: rotate(45deg) translateY(105px) translateX(49px);
	left: 100%;
	width: 250px;
	border: 2px solid var(--yellow-field-color);
	z-index: 0;
	box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 10px var(--yellow-border-color), 0 0 15px var(--yellow-border-color),
		0 0 20px var(--yellow-border-color), 0 0 25px var(--yellow-border-color) inset;
}
.yellow-field.column-2.row-2:before {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	transform: rotate(45deg) translateY(27px) translateX(-76px);
	left: 100%;
	width: 30px;
	border: 2px solid var(--yellow-field-color);
	box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 10px var(--yellow-border-color), 0 0 15px var(--yellow-border-color),
		0 0 20px var(--yellow-border-color), 0 0 25px var(--yellow-border-color) inset;
	z-index: 0;
}

/*********************** Blue *************************/

/** Arrows **/
.blue-field.row-1:after {
	content: "";
	box-sizing: border-box;

	position: absolute;
	top: 100%;
	height: 160px;
	border: 2px solid var(--blue-border-color);
	box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 10px var(--blue-border-color), 0 0 15px var(--blue-border-color),
		0 0 20px var(--blue-border-color), 0 0 25px var(--blue-border-color) inset;
}
.blue-field.row-2.column-1:after {
	content: "";
	box-sizing: border-box;
	position: absolute;
	top: 100%;
	height: 100px;
	border: 2px solid var(--blue-border-color);
	box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 10px var(--blue-border-color), 0 0 15px var(--blue-border-color),
		0 0 20px var(--blue-border-color), 0 0 25px var(--blue-border-color) inset;
}

.blue-field.column-1:before {
	content: "";
	box-sizing: border-box;

	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 100%;
	width: 250px;
	border: 2px solid var(--blue-border-color);
	box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 10px var(--blue-border-color), 0 0 15px var(--blue-border-color),
		0 0 20px var(--blue-border-color), 0 0 25px var(--blue-border-color) inset;
}
.blue-field.column-2.row-1:before {
	content: "";
	box-sizing: border-box;

	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 100%;
	width: 160px;
	border: 2px solid var(--blue-border-color);
	box-shadow: 0 0 2px #fff, 0 0 10px #fff, 0 0 10px var(--blue-border-color), 0 0 15px var(--blue-border-color),
		0 0 20px var(--blue-border-color), 0 0 25px var(--blue-border-color) inset;
}
.blue-points-container {
	grid-column: 1 / 6;
	grid-row: 1 / 2;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	border-bottom: 2px solid var(--blue-border-color);
}

.blue-points-content,
.blue-points-numbers {
	display: grid;
	grid-template-columns: repeat(11, 1fr);
}

.description {
	grid-row: 2 / 3;
	grid-column: 1 / 2;
}
.blue-display-dice {
	color: blue;
}
.white-display-dice {
	color: white;
}
.display {
	font-size: 2em;
}
/*********************** /Purple *************************/

.smaller-sign {
	z-index: 5;
	position: absolute;
	top: 40%;
	left: 75%;
	transform: translateY(-50%);
	font-size: 50px;
	font-family: Cursive;
	color: var(--purple-font-color);
}
.field-group:last-of-type .smaller-sign {
	display: none;
}
.possible {
	background-color: rgb(97, 131, 81);
}

/*********************** Orange *************************/
.possible-choice {
	border: 6px solid rgb(71, 0, 119);
	cursor: pointer;
}

