.menu-container {
	position: absolute;
	width: 100%;
	border-top: 1px solid var(--text-color);
}
.menu-button {
	position: absolute;
	color: var(--text-color);
	border: 1px solid var(--text-color);
	cursor: pointer;
	align-items: center;
	justify-content: center;
	right: 20px;
	top: 20px;
	z-index: 10;
	transition: all ease-in-out 250ms;
}
.menu-button:hover {
	background-color: var(--primary-color);
}
.active:hover {
	background-color: var(--tertiary-color);
}
.active {
	width: 200px;
	height: 150px;
	border-radius: 25px;
	cursor: default;
	background-color: var(--tertiary-color);
}
.menu-icon {
	font-size: 1.5rem;
	cursor: pointer;
}
.close:hover {
	opacity: 0.6;
}
.menu-item {
	padding: 3px;
	cursor: pointer;
}
.menu-item:hover {
	opacity: 0.6;
}
.menu-title {
	height: 40px;
}
.tutorial-popup {
	position: absolute;
	color: var(--text-color);
	border: 1px solid var(--text-color);
	justify-content: center;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	height: 90%;
	background-color: var(--primary-color);
	z-index: 10;
	width: 90%;
	padding: 25px;
	overflow-y: scroll;
}
.tutorial-close {
	position: absolute;
	right: 20px;
	cursor: pointer;
	width: 30px;
	height: 30px;
	color: var(--text-color);
	z-index: 20;
}

.tutorial-section {
	text-align: start;
}
.headline,
h3 {
	margin-top: 10px;
	font-weight: bold;
	background-color: rgb(138, 57, 57);
	padding: 5px;
	border-radius: 5px;
	width: 75px;
	text-align: center;
}
h3 {
	width: 125px;
}
.tutorial-popup .title {
	font-size: 2rem;
}
.bold {
	font-weight: bold;
	display: inline;
}

