.lobby {
	height: 100%;
}
.content-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 60%;
	padding: 10px;
	color: var(--text-color);
	font-weight: bold;
}
.left-container-lobby,
.right-container-lobby {
	width: 30%;
	height: 100%;
	margin: 20px;
}

.player-container {
	box-sizing: border-box;
	background-color: var(--secondary-color);
	border-radius: 10px;
	border: 3px solid var(--text-color);
	width: 100%;
	height: 90%;
	overflow: hidden;
	position: relative;
}

#copy-link-button,
#start-game-button {
	background-color: var(--secondary-color);
	border-radius: 10px;
	border: 3px solid var(--text-color);
	width: 100%;
	height: 10%;
	margin-top: 20px;
	color: var(--text-color);
	font-weight: bold;
	font-size: 1.2em;
	cursor: pointer;
}
#copy-link-button:hover,
#start-game-button:hover {
	background-color: rgba(255, 255, 255, 0.4);
}

